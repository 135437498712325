<template>
  <v-container class="px-3 py-3 mt-5">
     <h1>ADMIN</h1>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>